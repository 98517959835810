import classes from '../Offers/Offers.module.scss'
import report_classes from '../Admin/Reports.module.scss'


// packages
import { useState, useEffect } from 'react'


import axios from 'axios'
import { MultiSelect } from "react-multi-select-component"
import Tooltip from '@mui/material/Tooltip'


// components
import CustomButton from "../Customs/CustomButton/CustomButton"
import CustomInput from '../Customs/CustomInput/CustomInput'
import CustomTextArea from '../Customs/CustomInput/CustomTextArea'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import LoadingTable from '../LoadingTable/LoadingTable'


// helpers
import { displayDevices, displayCountries, ReportHeaders, getStatusDiv } from '../../utils/helper_functions'









const AdminOffers = () => {
    const API_URL = process.env.REACT_APP_API_URL

    const [toggleAddLinkPopUp, setToggleAddLinkPopUp] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [failLoading, setFailLoading] = useState(false)

    const [isNewOffer, setIsNewOffer] = useState(true)
    const [updateOfferId, setUpdateOfferId] = useState("")
    const [Data, setdata] = useState([])

    // const [offerId, setOfferId] = useState("")
    const [countries, setcountries] = useState("")

    const [vertical, setVertical]= useState("")
    const [selectedNations, setSelectedNations] = useState([])

    const [selectedCountry, setSelectedCountry] = useState('')
    const [error, seterror] = useState("")
    
    
    const [name, setName] = useState("")
    const [url, setUrl] = useState("")
    const [category, setcategory] = useState("")
    const [avgEpc, setavgEpc] = useState('')
    const [payoutPer, setpayoutPer] = useState("")
    const [devices, setdevices] = useState("")
    const [status, setStatus] = useState("")
    const [creativeUrl, setCreativeUrl] = useState("")

    const [description, setdescription] = useState("")
    const [cap, setCap] = useState("")
    const [restrictions, setRestrictions] = useState("")
    const [restrictionsMediaType, setRestrictionsMediaType] = useState("")

    const [thumbnailFile, setThumbnailFile] = useState()
    const [thumbnailUrl, setThumbnailUrl] = useState("")



    const [_id, setId] = useState("")
    const options = [
        { value: 'AF', label: 'Afghanistan'},
        { value: 'AX', label: 'Aland Islands'},
        { value: 'AL', label: 'Albania'},
        { value: 'DZ', label: 'Algeria'},
        { value: 'AS', label: 'American Samoa'},
        { value: 'AD', label: 'Andorra'},
        { value: 'AO', label: 'Angola'},
        { value: 'AI', label: 'Anguilla'},
        { value: 'AQ', label: 'Antarctica'},
        { value: 'AG', label: 'Antigua And Barbuda'},
        { value: 'AR', label: 'Argentina'},
        { value: 'AM', label: 'Armenia'},
        { value: 'AW', label: 'Aruba'},
        { value: 'AU', label: 'Australia'},
        { value: 'AT', label: 'Austria'},
        { value: 'AZ', label: 'Azerbaijan'},
        { value: 'BS', label: 'Bahamas'},
        { value: 'BH', label: 'Bahrain'},
        { value: 'BD', label: 'Bangladesh'},
        { value: 'BB', label: 'Barbados'},
        { value: 'BY', label: 'Belarus'},
        { value: 'BE', label: 'Belgium'},
        { value: 'BZ', label: 'Belize'},
        { value: 'BJ', label: 'Benin'},
        { value: 'BM', label: 'Bermuda'},
        { value: 'BT', label: 'Bhutan'},
        { value: 'BO', label: 'Bolivia'},
        { value: 'BA', label: 'Bosnia And Herzegovina'},
        { value: 'BW', label: 'Botswana'},
        { value: 'BV', label: 'Bouvet Island'},
        { value: 'BR', label: 'Brazil'},
        { value: 'IO', label: 'British Indian Ocean Territory'},
        { value: 'BN', label: 'Brunei Darussalam'},
        { value: 'BG', label: 'Bulgaria'},
        { value: 'BF', label: 'Burkina Faso'},
        { value: 'BI', label: 'Burundi'},
        { value: 'KH', label: 'Cambodia'},
        { value: 'CM', label: 'Cameroon'},
        { value: 'CA', label: 'Canada'},
        { value: 'CV', label: 'Cape Verde'},
        { value: 'KY', label: 'Cayman Islands'},
        { value: 'CF', label: 'Central African Republic'},
        { value: 'td', label: 'Chad'},
        { value: 'CL', label: 'Chile'},
        { value: 'CN', label: 'China'},
        { value: 'CX', label: 'Christmas Island'},
        { value: 'CC', label: 'Cocos (Keeling) Islands'},
        { value: 'CO', label: 'Colombia'},
        { value: 'KM', label: 'Comoros'},
        { value: 'CG', label: 'Congo'},
        { value: 'CD', label: 'Congo}, Democratic Republic'},
        { value: 'CK', label: 'Cook Islands'},
        { value: 'CR', label: 'Costa Rica'},
        { value: 'CI', label: 'Cote D\'Ivoire'},
        { value: 'HR', label: 'Croatia'},
        { value: 'CU', label: 'Cuba'},
        { value: 'CY', label: 'Cyprus'},
        { value: 'CZ', label: 'Czech Republic'},
        { value: 'DK', label: 'Denmark'},
        { value: 'DJ', label: 'Djibouti'},
        { value: 'DM', label: 'Dominica'},
        { value: 'DO', label: 'Dominican Republic'},
        { value: 'EC', label: 'Ecuador'},
        { value: 'EG', label: 'Egypt'},
        { value: 'SV', label: 'El Salvador'},
        { value: 'GQ', label: 'Equatorial Guinea'},
        { value: 'ER', label: 'Eritrea'},
        { value: 'EE', label: 'Estonia'},
        { value: 'ET', label: 'Ethiopia'},
        { value: 'FK', label: 'Falkland Islands (Malvinas)'},
        { value: 'FO', label: 'Faroe Islands'},
        { value: 'FJ', label: 'Fiji'},
        { value: 'FI', label: 'Finland'},
        { value: 'FR', label: 'France'},
        { value: 'GF', label: 'French Guiana'},
        { value: 'PF', label: 'French Polynesia'},
        { value: 'TF', label: 'French Southern Territories'},
        { value: 'GA', label: 'Gabon'},
        { value: 'GM', label: 'Gambia'},
        { value: 'GE', label: 'Georgia'},
        { value: 'DE', label: 'Germany'},
        { value: 'GH', label: 'Ghana'},
        { value: 'GI', label: 'Gibraltar'},
        { value: 'GR', label: 'Greece'},
        { value: 'GL', label: 'Greenland'},
        { value: 'GD', label: 'Grenada'},
        { value: 'GP', label: 'Guadeloupe'},
        { value: 'GU', label: 'Guam'},
        { value: 'GT', label: 'Guatemala'},
        { value: 'GG', label: 'Guernsey'},
        { value: 'GN', label: 'Guinea'},
        { value: 'GW', label: 'Guinea-Bissau'},
        { value: 'GY', label: 'Guyana'},
        { value: 'HT', label: 'Haiti'},
        { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
        { value: 'VA', label: 'Holy See (Vatican City State)'},
        { value: 'HN', label: 'Honduras'},
        { value: 'HK', label: 'Hong Kong'},
        { value: 'HU', label: 'Hungary'},
        { value: 'IS', label: 'Iceland'},
        { value: 'IN', label: 'India'},
        { value: 'ID', label: 'Indonesia'},
        { value: 'IR', label: 'Iran}, Islamic Republic Of'},
        { value: 'IQ', label: 'Iraq'},
        { value: 'IE', label: 'Ireland'},
        { value: 'IM', label: 'Isle Of Man'},
        { value: 'IL', label: 'Israel'},
        { value: 'IT', label: 'Italy'},
        { value: 'JM', label: 'Jamaica'},
        { value: 'JP', label: 'Japan'},
        { value: 'JE', label: 'Jersey'},
        { value: 'JO', label: 'Jordan'},
        { value: 'KZ', label: 'Kazakhstan'},
        { value: 'KE', label: 'Kenya'},
        { value: 'KI', label: 'Kiribati'},
        { value: 'KR', label: 'Korea'},
        { value: 'KW', label: 'Kuwait'},
        { value: 'KG', label: 'Kyrgyzstan'},
        { value: 'LA', label: 'Lao People\'s Democratic Republic'},
        { value: 'LV', label: 'Latvia'},
        { value: 'LB', label: 'Lebanon'},
        { value: 'LS', label: 'Lesotho'},
        { value: 'LR', label: 'Liberia'},
        { value: 'LY', label: 'Libyan Arab Jamahiriya'},
        { value: 'LI', label: 'Liechtenstein'},
        { value: 'LT', label: 'Lithuania'},
        { value: 'LU', label: 'Luxembourg'},
        { value: 'MO', label: 'Macao'},
        { value: 'MK', label: 'Macedonia'},
        { value: 'MG', label: 'Madagascar'},
        { value: 'MW', label: 'Malawi'},
        { value: 'MY', label: 'Malaysia'},
        { value: 'MV', label: 'Maldives'},
        { value: 'ML', label: 'Mali'},
        { value: 'MT', label: 'Malta'},
        { value: 'MH', label: 'Marshall Islands'},
        { value: 'MQ', label: 'Martinique'},
        { value: 'MR', label: 'Mauritania'},
        { value: 'MU', label: 'Mauritius'},
        { value: 'YT', label: 'Mayotte'},
        { value: 'MX', label: 'Mexico'},
        { value: 'FM', label: 'Micronesia}, Federated States Of'},
        { value: 'MD', label: 'Moldova'},
        { value: 'MC', label: 'Monaco'},
        { value: 'MN', label: 'Mongolia'},
        { value: 'ME', label: 'Montenegro'},
        { value: 'MS', label: 'Montserrat'},
        { value: 'MA', label: 'Morocco'},
        { value: 'MZ', label: 'Mozambique'},
        { value: 'MM', label: 'Myanmar'},
        { value: 'NA', label: 'Namibia'},
        { value: 'NR', label: 'Nauru'},
        { value: 'NP', label: 'Nepal'},
        { value: 'NL', label: 'Netherlands'},
        { value: 'AN', label: 'Netherlands Antilles'},
        { value: 'NC', label: 'New Caledonia'},
        { value: 'NZ', label: 'New Zealand'},
        { value: 'NI', label: 'Nicaragua'},
        { value: 'NE', label: 'Niger'},
        { value: 'NG', label: 'Nigeria'},
        { value: 'NU', label: 'Niue'},
        { value: 'NF', label: 'Norfolk Island'},
        { value: 'MP', label: 'Northern Mariana Islands'},
        { value: 'NO', label: 'Norway'},
        { value: 'OM', label: 'Oman'},
        { value: 'PK', label: 'Pakistan'},
        { value: 'PW', label: 'Palau'},
        { value: 'PS', label: 'Palestinian Territory}, Occupied'},
        { value: 'PA', label: 'Panama'},
        { value: 'PG', label: 'Papua New Guinea'},
        { value: 'PY', label: 'Paraguay'},
        { value: 'PE', label: 'Peru'},
        { value: 'PH', label: 'Philippines'},
        { value: 'PN', label: 'Pitcairn'},
        { value: 'PL', label: 'Poland'},
        { value: 'PT', label: 'Portugal'},
        { value: 'PR', label: 'Puerto Rico'},
        { value: 'QA', label: 'Qatar'},
        { value: 'RE', label: 'Reunion'},
        { value: 'RO', label: 'Romania'},
        { value: 'RU', label: 'Russian Federation'},
        { value: 'RW', label: 'Rwanda'},
        { value: 'BL', label: 'Saint Barthelemy'},
        { value: 'SH', label: 'Saint Helena'},
        { value: 'KN', label: 'Saint Kitts And Nevis'},
        { value: 'LC', label: 'Saint Lucia'},
        { value: 'MF', label: 'Saint Martin'},
        { value: 'PM', label: 'Saint Pierre And Miquelon'},
        { value: 'VC', label: 'Saint Vincent And Grenadines'},
        { value: 'WS', label: 'Samoa'},
        { value: 'SM', label: 'San Marino'},
        { value: 'ST', label: 'Sao Tome And Principe'},
        { value: 'SA', label: 'Saudi Arabia'},
        { value: 'SN', label: 'Senegal'},
        { value: 'RS', label: 'Serbia'},
        { value: 'SC', label: 'Seychelles'},
        { value: 'SL', label: 'Sierra Leone'},
        { value: 'SG', label: 'Singapore'},
        { value: 'SK', label: 'Slovakia'},
        { value: 'SI', label: 'Slovenia'},
        { value: 'SB', label: 'Solomon Islands'},
        { value: 'SO', label: 'Somalia'},
        { value: 'ZA', label: 'South Africa'},
        { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
        { value: 'ES', label: 'Spain'},
        { value: 'LK', label: 'Sri Lanka'},
        { value: 'SD', label: 'Sudan'},
        { value: 'SR', label: 'Suriname'},
        { value: 'SJ', label: 'Svalbard And Jan Mayen'},
        { value: 'SZ', label: 'Swaziland'},
        { value: 'SE', label: 'Sweden'},
        { value: 'CH', label: 'Switzerland'},
        { value: 'SY', label: 'Syrian Arab Republic'},
        { value: 'TW', label: 'Taiwan'},
        { value: 'TJ', label: 'Tajikistan'},
        { value: 'TZ', label: 'Tanzania'},
        { value: 'TH', label: 'Thailand'},
        { value: 'TL', label: 'Timor-Leste'},
        { value: 'TG', label: 'Togo'},
        { value: 'TK', label: 'Tokelau'},
        { value: 'TO', label: 'Tonga'},
        { value: 'TT', label: 'Trinidad And Tobago'},
        { value: 'TN', label: 'Tunisia'},
        { value: 'TR', label: 'Turkey'},
        { value: 'TM', label: 'Turkmenistan'},
        { value: 'TC', label: 'Turks And Caicos Islands'},
        { value: 'TV', label: 'Tuvalu'},
        { value: 'UG', label: 'Uganda'},
        { value: 'UA', label: 'Ukraine'},
        { value: 'AE', label: 'United Arab Emirates'},
        { value: 'GB', label: 'United Kingdom'},
        { value: 'US', label: 'United States'},
        { value: 'UM', label: 'United States Outlying Islands'},
        { value: 'UY', label: 'Uruguay'},
        { value: 'UZ', label: 'Uzbekistan'},
        { value: 'VU', label: 'Vanuatu'},
        { value: 'VE', label: 'Venezuela'},
        { value: 'VN', label: 'Viet Nam'},
        { value: 'VG', label: 'Virgin Islands}, British'},
        { value: 'VI', label: 'Virgin Islands}, U.S.'},
        { value: 'WF', label: 'Wallis And Futuna'},
        { value: 'EH', label: 'Western Sahara'},
        { value: 'YE', label: 'Yemen'},
        { value: 'ZM', label: 'Zambia'},
        { value: 'ZW', label: 'Zimbabwe'}
    ]


    const getAndLoadMyOffers = () =>{
        setLoading(true)
        
        let access_token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/admin_offers`,
            headers: { 
              'Authorization': 'Bearer '+access_token
            }
        };

        axios.request(config)
            .then((response) => {
                setLoading(false)
                if(response.data.offers){
                    setdata(response.data.offers)
                }
            })
            .catch((error) => {
                console.log(error)
                if(error && error.response && error.response.status >= 400 ){
                    localStorage.removeItem('access_token')
                    // window.location.href = "/login"
                }
                console.log(error)
            })
    }

    useEffect(()=>{
        getAndLoadMyOffers()
        document.title = "LinkVantage | Admin Offers"
    }, [])

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setSelectedCountry(selectedCountry)
    };

    const handleAssingOffer = () => {
        setofferAssingPopup(false)
    }

    const handleNewLinkAdd = (e) => {
        setIsNewOffer(true)
        setToggleAddLinkPopUp(!toggleAddLinkPopUp)
        seterror("")
        clearValues()
        setAutomatedConversionUrl("")
        setcategory("CPL")
        setVertical('eCommerce')
        setStatus('apply')
    }

    const handleSwitchChange = (id, newStatus) => {
        setLoading(true)
        
        let access_token = localStorage.getItem('access_token')
        let config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/toggle_offer/${id}`,
            headers: { 
              'Authorization': 'Bearer '+access_token
            }
        };
          
        axios.request(config)
            .then((response) => {
                 console.log(JSON.stringify(response.data))
                setLoading(false)
                getAndLoadMyOffers()
            })
            .catch((error) => {
                console.log(error)
                if(error && error.response && error.response.status >= 400 ){
                    localStorage.removeItem('access_token')
                }
            })
    }


    const addOffer = () => {
        setIsNewOffer(true)
        setToggleAddLinkPopUp(true)
        setId("")
        setName("")
        setUrl("")
        setpayoutPer("")
        setavgEpc("")
        setcategory("")
        setSelectedNations([])
        setVertical("")
        setdevices([])
        setCreativeUrl("")
        setStatus("")
    };

    const clearValues = () => {
        setId("")
        setName("")
        setUrl("")
        setpayoutPer("")
        setavgEpc("")
        setcategory("")
        setSelectedNations([])
        setVertical("")
        setdevices([])
        setCreativeUrl("")
        setStatus("")

        setCreativeUrl('')
        setdescription('')
        setCap('')
        setRestrictions('')
        setRestrictionsMediaType('')
        setThumbnailFile()
        setThumbnailUrl('')
    };

    const updateOffer = (offer) => {
        setIsNewOffer(false)
        setUpdateOfferId(offer._id)
        setId(offer._id)
        setName(offer.name)
        setUrl(offer.url)
        setpayoutPer(offer.payoutPer)
        setavgEpc(offer.avgEpc)
        setcategory(offer.category)
        setSelectedNationsUpdate(offer.countries)
        setVertical(offer.vertical)
        setdevices(offer.devices)
        setStatus(offer.status)
        setToggleAddLinkPopUp(true)
        setAutomatedConversionUrl(offer.automatedConversionUrl)

        setCreativeUrl(offer.creative_url)
        setdescription(offer.description)
        setCap(offer.cap)
        setRestrictions(offer.restrictions)
        setRestrictionsMediaType(offer.restrictions_media_type)
        setThumbnailUrl(offer.thumbnail)
    }

    const assignOffer = (offer_id) => {
        console.log("offer", offer_id)
        setofferAssingPopup(true)
        // setofferAssingPopup(true)
    }

        
        const renderOffers = () => {
            let offersData = []
            
            Data.forEach((item, index) =>  {
                offersData.push(
                <tr key={index} >
                    <td>{index+1}</td>
                    {/* <td><Link to={"/offer-report/"+item._id+"?return=/admin-offers"}>{item.name}</Link></td> */}
                    <td>{item.name}</td>
                    <td>{item.url}</td>
                    <td>{item.category}</td>
                    <td>{(item.category==="CPA" || item.category==="CPL")?`$${item.payoutPer}`:`${item.payoutPer}%`}</td>
                    <td>{displayCountries(item.countries)}</td>
                    <td>{item.vertical}</td>
                    <td>{displayDevices(item.devices)}</td>         
                    <td>
                        {getStatusDiv(item.status)}
                        {
                            (item.status=="hidden")
                            &&
                            <>
                            <CustomButton color='blue' name="Assing Offer" onClick={()=>assignOffer(item._id)}/>
                            </>
                        }
                    </td>
                    <td>
                        <div>
                            <Tooltip title={item.active_status?"Deactivate offer":"Activate offer"} placement="top" arrow>
                            <label className={report_classes.checkbox}>
                                <input type="checkbox" checked={item.active_status} onChange={()=>{handleSwitchChange(item._id, !item.active_status)}}/>
                                <span></span>
                            </label>
                            </Tooltip>
                        </div>
                    </td>
                    <td><CustomButton color='blue' name="Edit Offer" onClick={()=>updateOffer(item)}/></td>
            </tr>)
        })

        if(Data.length===0){
            offersData.push(<tr key={0}>
                <td style={{textAlign:"center"}} colSpan={8}>No Data</td>
            </tr>)
        }
        return offersData
    }



    const setSelectedNationsAll = (selecteds) => {
        const checkedNations = Array.from(selecteds).map(item => item.value)
        setSelectedNations(checkedNations)
    }

    const setSelectedNationsUpdate = (selected) => {
        const countries = [];

        Array.from(selected).map(item => {
            if(item != null)
            {
                let returnc = allcountries.filter((country) => {
                    return country.value === item;
                })
                countries.push(returnc[0])
            }
        })

        setSelectedNations(countries)
    }

    const [offerAssingPopup, setofferAssingPopup] = useState(false);

    const submitHandler = async() => {
        console.log("name :", name, !name)
        console.log("url :", url, !url)
        console.log("category :", category, !category)
        console.log("payoutPer :", payoutPer, !payoutPer)
        console.log("selectedNations :", selectedNations, selectedNations.length===0)
        console.log("vertical :", vertical, !vertical)
        console.log("devices :", devices, devices.length===0)
        console.log("status :", status, !status)

        if(!name || !url || !payoutPer || !category || selectedNations.length===0 || !vertical || devices.length===0 || !status){
            seterror("All Fields required")
            return
        }

        const countries = Array.from(selectedNations).map(item => item.value)
        seterror("")
        setLoading(false)
        
        const formData = new FormData()
        var all_data;
        if(isNewOffer){
            all_data = JSON.stringify({
                name,
                url,
                category,
                payoutPer,
                countries,
                vertical,
                devices,
                status,
                description,
                cap,
                restrictions,
                restrictionsMediaType,
                creativeUrl,
                automatedConversionUrl,
              })
        }else{
            all_data = JSON.stringify({
                _id,
                name,
                url,
                category,
                payoutPer,
                countries,
                vertical,
                devices,
                status,
                description,
                cap,
                restrictions,
                restrictionsMediaType,
                creativeUrl,
                automatedConversionUrl,
            })
        }
        if(thumbnailFile){
            formData.append('file', thumbnailFile)
        }
        formData.append('data', all_data)

        let access_token = localStorage.getItem('access_token')
        const request_options = {
            method: isNewOffer ? 'POST' : 'PUT',
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: formData
        }
        
        // console.log("all_data is :", JSON.parse(all_data), thumbnailFile)
        let API_ENDPOINT = isNewOffer ? `${API_URL}/api/v1/create_offer` : `${API_URL}/api/v1/update_offer/${updateOfferId}`;
        let response = await fetch(API_ENDPOINT, request_options)
        let res_code = response.status;
        let responseBody = await response.json()
        
        console.log("\n\nres is :", res_code, responseBody)

        if (res_code >= 400) {
          setLoading(false)
          return;
        }

        if(res_code === 200) {
            clearValues()
            setToggleAddLinkPopUp(false)
            getAndLoadMyOffers()
        }
    }

    const [automatedConversionUrl, setAutomatedConversionUrl] = useState("");


    const inputChangeHandler = (e) => {
        let name = e.target.name
        let value = e.target.value

        // let checked = e.target.checked
        // let type = e.target.type
        
        if(name === 'name') {
            setName(value)
        } else if(name === 'url') {
            setUrl(value)
        } else if(name === 'avgEpc') {
            setavgEpc(value)
        } else if(name === 'payoutPer') {
            setpayoutPer(value)
        } else if(name === 'category') {
            setcategory(value)
        } else if(name === 'automated_conversion') {
            setAutomatedConversionUrl(value)
        } else if(name === 'countries') {
            const arrayValue = value.split(',').map(item => item.trim().toUpperCase())
            setcountries(arrayValue)
        } else if(name === 'vertical') {
            setVertical(value)
        } else if (name === 'devices') {
            const checkedDevices = Array.from(document.querySelectorAll('input[name="devices"]:checked')).map(item => item.value)
            setdevices(checkedDevices)
        } else if (name === 'status') {
            setStatus(value)
        } else if (name === 'creativeUrl') {
            setCreativeUrl(value)
        } else if (name === 'description') {
            setdescription(value)
        } else if (name === 'cap') {
            setCap(value)
        } else if (name === 'restrictions') {
            setRestrictions(value)
        } else if (name === 'restrictions_media_type') {
            setRestrictionsMediaType(value)
        } 
    }

    const allcountries = [
        { value: 'ALL', label: 'Worldwide'},
        { value: 'AF', label: 'Afghanistan'},
        { value: 'AX', label: 'Aland Islands'},
        { value: 'AL', label: 'Albania'},
        { value: 'DZ', label: 'Algeria'},
        { value: 'AS', label: 'American Samoa'},
        { value: 'AD', label: 'Andorra'},
        { value: 'AO', label: 'Angola'},
        { value: 'AI', label: 'Anguilla'},
        { value: 'AQ', label: 'Antarctica'},
        { value: 'AG', label: 'Antigua And Barbuda'},
        { value: 'AR', label: 'Argentina'},
        { value: 'AM', label: 'Armenia'},
        { value: 'AW', label: 'Aruba'},
        { value: 'AU', label: 'Australia'},
        { value: 'AT', label: 'Austria'},
        { value: 'AZ', label: 'Azerbaijan'},
        { value: 'BS', label: 'Bahamas'},
        { value: 'BH', label: 'Bahrain'},
        { value: 'BD', label: 'Bangladesh'},
        { value: 'BB', label: 'Barbados'},
        { value: 'BY', label: 'Belarus'},
        { value: 'BE', label: 'Belgium'},
        { value: 'BZ', label: 'Belize'},
        { value: 'BJ', label: 'Benin'},
        { value: 'BM', label: 'Bermuda'},
        { value: 'BT', label: 'Bhutan'},
        { value: 'BO', label: 'Bolivia'},
        { value: 'BA', label: 'Bosnia And Herzegovina'},
        { value: 'BW', label: 'Botswana'},
        { value: 'BV', label: 'Bouvet Island'},
        { value: 'BR', label: 'Brazil'},
        { value: 'IO', label: 'British Indian Ocean Territory'},
        { value: 'BN', label: 'Brunei Darussalam'},
        { value: 'BG', label: 'Bulgaria'},
        { value: 'BF', label: 'Burkina Faso'},
        { value: 'BI', label: 'Burundi'},
        { value: 'KH', label: 'Cambodia'},
        { value: 'CM', label: 'Cameroon'},
        { value: 'CA', label: 'Canada'},
        { value: 'CV', label: 'Cape Verde'},
        { value: 'KY', label: 'Cayman Islands'},
        { value: 'CF', label: 'Central African Republic'},
        { value: 'td', label: 'Chad'},
        { value: 'CL', label: 'Chile'},
        { value: 'CN', label: 'China'},
        { value: 'CX', label: 'Christmas Island'},
        { value: 'CC', label: 'Cocos (Keeling) Islands'},
        { value: 'CO', label: 'Colombia'},
        { value: 'KM', label: 'Comoros'},
        { value: 'CG', label: 'Congo'},
        { value: 'CD', label: 'Congo}, Democratic Republic'},
        { value: 'CK', label: 'Cook Islands'},
        { value: 'CR', label: 'Costa Rica'},
        { value: 'CI', label: 'Cote D\'Ivoire'},
        { value: 'HR', label: 'Croatia'},
        { value: 'CU', label: 'Cuba'},
        { value: 'CY', label: 'Cyprus'},
        { value: 'CZ', label: 'Czech Republic'},
        { value: 'DK', label: 'Denmark'},
        { value: 'DJ', label: 'Djibouti'},
        { value: 'DM', label: 'Dominica'},
        { value: 'DO', label: 'Dominican Republic'},
        { value: 'EC', label: 'Ecuador'},
        { value: 'EG', label: 'Egypt'},
        { value: 'SV', label: 'El Salvador'},
        { value: 'GQ', label: 'Equatorial Guinea'},
        { value: 'ER', label: 'Eritrea'},
        { value: 'EE', label: 'Estonia'},
        { value: 'ET', label: 'Ethiopia'},
        { value: 'FK', label: 'Falkland Islands (Malvinas)'},
        { value: 'FO', label: 'Faroe Islands'},
        { value: 'FJ', label: 'Fiji'},
        { value: 'FI', label: 'Finland'},
        { value: 'FR', label: 'France'},
        { value: 'GF', label: 'French Guiana'},
        { value: 'PF', label: 'French Polynesia'},
        { value: 'TF', label: 'French Southern Territories'},
        { value: 'GA', label: 'Gabon'},
        { value: 'GM', label: 'Gambia'},
        { value: 'GE', label: 'Georgia'},
        { value: 'DE', label: 'Germany'},
        { value: 'GH', label: 'Ghana'},
        { value: 'GI', label: 'Gibraltar'},
        { value: 'GR', label: 'Greece'},
        { value: 'GL', label: 'Greenland'},
        { value: 'GD', label: 'Grenada'},
        { value: 'GP', label: 'Guadeloupe'},
        { value: 'GU', label: 'Guam'},
        { value: 'GT', label: 'Guatemala'},
        { value: 'GG', label: 'Guernsey'},
        { value: 'GN', label: 'Guinea'},
        { value: 'GW', label: 'Guinea-Bissau'},
        { value: 'GY', label: 'Guyana'},
        { value: 'HT', label: 'Haiti'},
        { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
        { value: 'VA', label: 'Holy See (Vatican City State)'},
        { value: 'HN', label: 'Honduras'},
        { value: 'HK', label: 'Hong Kong'},
        { value: 'HU', label: 'Hungary'},
        { value: 'IS', label: 'Iceland'},
        { value: 'IN', label: 'India'},
        { value: 'ID', label: 'Indonesia'},
        { value: 'IR', label: 'Iran}, Islamic Republic Of'},
        { value: 'IQ', label: 'Iraq'},
        { value: 'IE', label: 'Ireland'},
        { value: 'IM', label: 'Isle Of Man'},
        { value: 'IL', label: 'Israel'},
        { value: 'IT', label: 'Italy'},
        { value: 'JM', label: 'Jamaica'},
        { value: 'JP', label: 'Japan'},
        { value: 'JE', label: 'Jersey'},
        { value: 'JO', label: 'Jordan'},
        { value: 'KZ', label: 'Kazakhstan'},
        { value: 'KE', label: 'Kenya'},
        { value: 'KI', label: 'Kiribati'},
        { value: 'KR', label: 'Korea'},
        { value: 'KW', label: 'Kuwait'},
        { value: 'KG', label: 'Kyrgyzstan'},
        { value: 'LA', label: 'Lao People\'s Democratic Republic'},
        { value: 'LV', label: 'Latvia'},
        { value: 'LB', label: 'Lebanon'},
        { value: 'LS', label: 'Lesotho'},
        { value: 'LR', label: 'Liberia'},
        { value: 'LY', label: 'Libyan Arab Jamahiriya'},
        { value: 'LI', label: 'Liechtenstein'},
        { value: 'LT', label: 'Lithuania'},
        { value: 'LU', label: 'Luxembourg'},
        { value: 'MO', label: 'Macao'},
        { value: 'MK', label: 'Macedonia'},
        { value: 'MG', label: 'Madagascar'},
        { value: 'MW', label: 'Malawi'},
        { value: 'MY', label: 'Malaysia'},
        { value: 'MV', label: 'Maldives'},
        { value: 'ML', label: 'Mali'},
        { value: 'MT', label: 'Malta'},
        { value: 'MH', label: 'Marshall Islands'},
        { value: 'MQ', label: 'Martinique'},
        { value: 'MR', label: 'Mauritania'},
        { value: 'MU', label: 'Mauritius'},
        { value: 'YT', label: 'Mayotte'},
        { value: 'MX', label: 'Mexico'},
        { value: 'FM', label: 'Micronesia}, Federated States Of'},
        { value: 'MD', label: 'Moldova'},
        { value: 'MC', label: 'Monaco'},
        { value: 'MN', label: 'Mongolia'},
        { value: 'ME', label: 'Montenegro'},
        { value: 'MS', label: 'Montserrat'},
        { value: 'MA', label: 'Morocco'},
        { value: 'MZ', label: 'Mozambique'},
        { value: 'MM', label: 'Myanmar'},
        { value: 'NA', label: 'Namibia'},
        { value: 'NR', label: 'Nauru'},
        { value: 'NP', label: 'Nepal'},
        { value: 'NL', label: 'Netherlands'},
        { value: 'AN', label: 'Netherlands Antilles'},
        { value: 'NC', label: 'New Caledonia'},
        { value: 'NZ', label: 'New Zealand'},
        { value: 'NI', label: 'Nicaragua'},
        { value: 'NE', label: 'Niger'},
        { value: 'NG', label: 'Nigeria'},
        { value: 'NU', label: 'Niue'},
        { value: 'NF', label: 'Norfolk Island'},
        { value: 'MP', label: 'Northern Mariana Islands'},
        { value: 'NO', label: 'Norway'},
        { value: 'OM', label: 'Oman'},
        { value: 'PK', label: 'Pakistan'},
        { value: 'PW', label: 'Palau'},
        { value: 'PS', label: 'Palestinian Territory}, Occupied'},
        { value: 'PA', label: 'Panama'},
        { value: 'PG', label: 'Papua New Guinea'},
        { value: 'PY', label: 'Paraguay'},
        { value: 'PE', label: 'Peru'},
        { value: 'PH', label: 'Philippines'},
        { value: 'PN', label: 'Pitcairn'},
        { value: 'PL', label: 'Poland'},
        { value: 'PT', label: 'Portugal'},
        { value: 'PR', label: 'Puerto Rico'},
        { value: 'QA', label: 'Qatar'},
        { value: 'RE', label: 'Reunion'},
        { value: 'RO', label: 'Romania'},
        { value: 'RU', label: 'Russian Federation'},
        { value: 'RW', label: 'Rwanda'},
        { value: 'BL', label: 'Saint Barthelemy'},
        { value: 'SH', label: 'Saint Helena'},
        { value: 'KN', label: 'Saint Kitts And Nevis'},
        { value: 'LC', label: 'Saint Lucia'},
        { value: 'MF', label: 'Saint Martin'},
        { value: 'PM', label: 'Saint Pierre And Miquelon'},
        { value: 'VC', label: 'Saint Vincent And Grenadines'},
        { value: 'WS', label: 'Samoa'},
        { value: 'SM', label: 'San Marino'},
        { value: 'ST', label: 'Sao Tome And Principe'},
        { value: 'SA', label: 'Saudi Arabia'},
        { value: 'SN', label: 'Senegal'},
        { value: 'RS', label: 'Serbia'},
        { value: 'SC', label: 'Seychelles'},
        { value: 'SL', label: 'Sierra Leone'},
        { value: 'SG', label: 'Singapore'},
        { value: 'SK', label: 'Slovakia'},
        { value: 'SI', label: 'Slovenia'},
        { value: 'SB', label: 'Solomon Islands'},
        { value: 'SO', label: 'Somalia'},
        { value: 'ZA', label: 'South Africa'},
        { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
        { value: 'ES', label: 'Spain'},
        { value: 'LK', label: 'Sri Lanka'},
        { value: 'SD', label: 'Sudan'},
        { value: 'SR', label: 'Suriname'},
        { value: 'SJ', label: 'Svalbard And Jan Mayen'},
        { value: 'SZ', label: 'Swaziland'},
        { value: 'SE', label: 'Sweden'},
        { value: 'CH', label: 'Switzerland'},
        { value: 'SY', label: 'Syrian Arab Republic'},
        { value: 'TW', label: 'Taiwan'},
        { value: 'TJ', label: 'Tajikistan'},
        { value: 'TZ', label: 'Tanzania'},
        { value: 'TH', label: 'Thailand'},
        { value: 'TL', label: 'Timor-Leste'},
        { value: 'TG', label: 'Togo'},
        { value: 'TK', label: 'Tokelau'},
        { value: 'TO', label: 'Tonga'},
        { value: 'TT', label: 'Trinidad And Tobago'},
        { value: 'TN', label: 'Tunisia'},
        { value: 'TR', label: 'Turkey'},
        { value: 'TM', label: 'Turkmenistan'},
        { value: 'TC', label: 'Turks And Caicos Islands'},
        { value: 'TV', label: 'Tuvalu'},
        { value: 'UG', label: 'Uganda'},
        { value: 'UA', label: 'Ukraine'},
        { value: 'AE', label: 'United Arab Emirates'},
        { value: 'GB', label: 'United Kingdom'},
        { value: 'US', label: 'United States'},
        { value: 'UM', label: 'United States Outlying Islands'},
        { value: 'UY', label: 'Uruguay'},
        { value: 'UZ', label: 'Uzbekistan'},
        { value: 'VU', label: 'Vanuatu'},
        { value: 'VE', label: 'Venezuela'},
        { value: 'VN', label: 'Viet Nam'},
        { value: 'VG', label: 'Virgin Islands}, British'},
        { value: 'VI', label: 'Virgin Islands}, U.S.'},
        { value: 'WF', label: 'Wallis And Futuna'},
        { value: 'EH', label: 'Western Sahara'},
        { value: 'YE', label: 'Yemen'},
        { value: 'ZM', label: 'Zambia'},
        { value: 'ZW', label: 'Zimbabwe'}
    ]






    // handle thumbnail file upload
    const handleThumbnailUpload = (e) => {
        setThumbnailFile(e.target.files[0])
    }





    const admin_offers_table_headers = ["#", "Name", "Url", "Price Format", "Payout", "Allowed Countries", "Vertical", "Platform", "Status", "Active?", "Actions"]

    return (
        <>  
             {loading && <LoadingTable />} 
            {/*{failLoading && <FailAnimation />}*/}


            {/* offer popup with trackable link to the offer */}
            {toggleAddLinkPopUp && 
            <PopUpCard loading={loading} bigPopup={true} header={isNewOffer?"Add Offer": "Update Offer"} toggle={handleNewLinkAdd}>
                <div style={{width:"100%", textAlign:"center", color:"red"}}>{error}</div>

                <form className={classes.form}>
                    <div className={classes.popup_content_main}>
                        <div className={classes.popup_content_left}>      
                            <CustomInput 
                                inline_label={<span>Name <span  className={classes.red_asterisk}>*</span></span>}
                                name='name'
                                type='text'
                                onChange={inputChangeHandler}
                                value={name}
                                className="offer-input custom_select_wrapper"
                                required

                            />
                            <CustomInput 
                                inline_label={<span>Affiliate Link <span  className={classes.red_asterisk}>*</span></span>}
                                name='url'
                                type='text'
                                onChange={inputChangeHandler}
                                value={url}
                                className="offer-input custom_select_wrapper"
                                required
                            />                                        
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Price Format </div>
                                <select
                                    name='category'
                                    onChange={inputChangeHandler}
                                    style={{
                                        borderStyle: "none",
                                        outline: "none",
                                        color: "#374957",
                                        width: "100%",
                                    }}
                                >                               
                                    {category && (
                                        <option value={category} selected>
                                            {category}
                                        </option>
                                    )}
                                    {/* <option value="">Select</option> */}

                                    {["CPL", "Content Arbitrage", "Search Arbitrage", "CPA"]
                                        .filter(option => option !== category)
                                        .map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {
                                category==="CPA" &&
                                <CustomInput 
                                    inline_label={<span>Automated Conversion</span>}
                                    name='automated_conversion'
                                    type='text'
                                    onChange={inputChangeHandler}
                                    value={automatedConversionUrl}
                                    className="offer-input custom_select_wrapper"
                                    required
                                />
                            }

                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Payout {category !== "Content Arbitrage" && <span>($)</span>}</div>
                                <input
                                    inline_label="Payout"
                                    name='payoutPer'
                                    type='number'
                                    onChange={inputChangeHandler}
                                    value={payoutPer}
                                    className="offer-input custom_select_wrapper"
                                    required
                                    style={{
                                        borderStyle: "none",
                                        outline: "none",
                                        fontSize: "1em",
                                        color: "#374957",
                                        width: "calc(100% - 1em)",
                                    }}
                                />
                                {category === "Content Arbitrage" && <span style={{marginLeft:"2px"}}></span>}
                            </div>
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Countries</div>
                                <MultiSelect
                                    hasSelectAll={false} 
                                    options={allcountries}
                                    value={selectedNations}
                                    onChange={setSelectedNations}
                                    labelledBy="Select"
                                />
                            </div>
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Vertical</div>
                                <select name='vertical' onChange={inputChangeHandler} style={{
                                    borderStyle: "none",
                                    outline: "none",
                                    fontSize: "1em",
                                    color: "#374957",
                                    width: "100%"
                                }}>
                                    <option value={"eCommerce"} selected={vertical==="eCommerce"}>eCommerce</option>
                                    <option value={"Listing"} selected={vertical==="Listing"}>Listing</option>
                                    <option value={"Sweepstakes"} selected={vertical==="Sweepstakes"}>Sweepstakes</option>
                                    <option value={"Adoption"} selected={vertical==="Adoption"}>Adoption</option>
                                    <option value={"Beauty"} selected={vertical==="Beauty"}>Beauty</option>
                                    <option value={"Biz Opp"} selected={vertical==="Biz Opp"}>Biz Opp</option>
                                </select>
                            </div>

                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Platforms </div>
                            </div>

                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="platform_label">Desktop</div>
                                <div className="form-list">
                                    <ul>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="mac"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("mac")}
                                            />
                                            Mac
                                        </li>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="windows"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("windows")}
                                            />
                                            Windows
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="devices"
                                                value="desktopOthers"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("desktopOthers")}
                                            />
                                            Others
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="platform_label">Mobile</div>
                                <div className="form-list">
                                    <ul>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="mobileAndroid"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("mobileAndroid")}
                                            />
                                            Android
                                        </li>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="mobileIos"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("mobileIos")}
                                            />
                                            iOS
                                        </li>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="mobileOthers"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("mobileOthers")}
                                            />
                                            Others
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="platform_label">Tablet</div>
                                <div className="form-list">
                                    <ul>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="tabletAndroid"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("tabletAndroid")}
                                            />
                                            Android
                                        </li>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="tabletIos"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("tabletIos")}
                                            />
                                            iOS
                                        </li>
                                        <li>
                                            <input 
                                                type="checkbox"
                                                name="devices"
                                                value="tabletOthers"
                                                onChange={inputChangeHandler}
                                                checked={devices.includes("tabletOthers")}
                                            />
                                            Others
                                        </li>
                                    </ul>
                                </div>
                            </div>  
                        </div>


                        <div className={classes.popup_content_right}>                                      
                            <div className="select-container offer-input custom_select_wrapper">
                                <div className="label">Status </div>
                                <select
                                    name='status'
                                    onChange={inputChangeHandler}
                                    style={{
                                        borderStyle: "none",
                                        outline: "none",
                                        fontSize: "1em",
                                        color: "#374957",
                                        width: "100%",
                                    }}>    

                                    {/* {status !== "" ? (
                                        <option value={status===1?"active":status} selected>{status===1?"Active":getStatusText(status)}</option>
                                    ):(
                                        <option value="" disabled>Select</option>
                                    )} */}

                                    {/* <option key="Apply To Run" value="apply">Apply To Run</option>
                                    <option key="Pending" value="pending">Pending</option>
                                    <option key="Active" value="active">Active</option>
                                    <option key="Public" value="public">Public</option> */}

                                    {/* <option value="" disabled>Select</option> */}
                                    <option key="Apply To Run" value="apply" selected={status==='apply'}>Apply To Run</option>
                                    <option key="Pending" value="pending" selected={status==='pending'}>Pending</option>
                                    <option key="Hidden" value="hidden" selected={status==='hidden'}>Hidden</option>
                                    {/* <option key="Active" value="active" selected={status==='active'}>Active</option>
                                    <option key="Public" value="public" selected={status==='public'}>Public</option> */}
                                </select>
                            </div>

                            <CustomInput 
                                inline_label="Creative Folder"
                                name='creativeUrl'
                                type='text'                                           
                                onChange={inputChangeHandler}
                                value={creativeUrl}
                                className="offer-input custom_select_wrapper"
                                required
                            /> 


                            <CustomTextArea 
                                inline_label={<span>Description</span>}
                                name='description'
                                type='text'
                                onChange={inputChangeHandler}
                                value={description}
                                className="offer-input custom_select_wrapper"
                            />

                            <CustomTextArea 
                                inline_label={<span>Cap</span>}
                                name='cap'
                                type='text'
                                onChange={inputChangeHandler}
                                value={cap}
                                className="offer-input custom_select_wrapper"
                            />

                            <CustomTextArea 
                                inline_label={<span>Restrictions</span>}
                                name='restrictions'
                                type='text'
                                onChange={inputChangeHandler}
                                value={restrictions}
                                className="offer-input custom_select_wrapper"
                            />

                            <CustomTextArea 
                                inline_label={<span>Restrictions Media Type</span>}
                                name='restrictions_media_type'
                                type='text'
                                onChange={inputChangeHandler}
                                value={restrictionsMediaType}
                                className="offer-input custom_select_wrapper"
                            />


                            <div className={classes.extra_margin_top}>
                                {thumbnailUrl &&
                                    <div className="select-container offer-input custom_select_wrapper">
                                        <div>Thumbnail</div>
                                        <div className={classes.popup_item_thumbnail}>
                                            <img src={thumbnailUrl} alt={thumbnailUrl}/>                               
                                        </div>
                                    </div>
                                }
                                <div className="select-container offer-input custom_select_wrapper">
                                    <div>Change Thumbnail</div>
                                    <div className="form-list">
                                        <input 
                                            name="file" 
                                            type="file" 
                                            onChange={handleThumbnailUpload}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={classes.popup_hr_without_margin}/>
                    <div className={classes.middle_copy_div_btn}>
                        <CustomButton color='blue' name={isNewOffer?"Add Offer":"Update"} type='button' onClick={submitHandler} />
                    </div>
                </form>
            </PopUpCard>}


            {offerAssingPopup && 
            <PopUpCard loading={loading} bigPopup={true} header={"Assing Offer"} toggle={handleAssingOffer}>
                <div style={{width:"100%", textAlign:"center", color:"red"}}>{error}</div>

                <form className={classes.form}>
                    <div className={classes.popup_content_main}>
                    </div>
                </form>
            </PopUpCard>
            }


            {/* offers main component section */}
            <div className={report_classes.container}>
                <div className={report_classes.main}>
                    <div className={report_classes.main_middle}>
                        <div className={`${report_classes.pagination_search_div} ${report_classes.margin_bottom}`}>
                            <div className={report_classes.main_title}><div className="heading">Admin Offers</div></div>
                            <div className={report_classes.main_filter}><div className={report_classes.customButton}><CustomButton color='green' name="Add Offer" onClick={handleNewLinkAdd}/></div></div> 
                        </div>
                        <div className={report_classes.table_div}>
                            <ResponsiveTable loadingResults={loading}>
                                <thead><ReportHeaders header_names={admin_offers_table_headers}/></thead>
                                <tbody>{renderOffers()}</tbody>
                            </ResponsiveTable>
                            {/* {Data && Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>} */}
                        </div>
                    </div>
                    {/* <div className={classes.main_bottom}>
                        {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default AdminOffers;
