import classes from './helpers.module.scss'
import offer_classes from '../components/Offers/Offers.module.scss'
import report_classes from '../components/Admin/Reports.module.scss'
import tools_classes from '../components/Tools/Tools.module.scss'
import '../components/Customs/ResponsiveTable/SuperResponsiveTableStyle.css'

import globe from '../assets/icons/globe.svg'
import imageDesktop from '../assets/images/desktop.png'
import imageApple from '../assets/images/apple.png'
import imageWindows from '../assets/images/windows.png'
import imageOthers from '../assets/images/devices/others.png'
import imagePhone from '../assets/images/iphone.png'
import imageAndroid from '../assets/images/android.png'
import imageTablet from '../assets/images/tablet.png'
import foreshopSmallT from '../assets/images/logo-small.png'

import LoadingTable from '../components/LoadingTable/LoadingTable'
import Tooltip from '@mui/material/Tooltip'
import { toast } from 'react-toastify'

import htmlparser from 'html-react-parser'




// #################################### select month rendering ####################################### 
export const renderMonthsSelectDiv = (monthData, selectedMonth, setSelectedMonth, setGetPrArRevs) => {
    // console.log("Month data :", monthData, selectedMonth);

    return (
        <div className={report_classes.select_search_item_div}>
            <select className={`${report_classes.form_select}`} 
                id="month"  
                name='month' 
                type='text' 
                onChange={(e)=>{setSelectedMonth(e.target.value); setGetPrArRevs(true);}}>
                <option selected={selectedMonth === 'all'? true: false} value='all'>All</option>
                {monthData.map((each_item, index)=>{return <option selected={selectedMonth === each_item? true : false} value={each_item}>{each_item}</option>})}
            </select>
        </div>
    )
}


// #################################### show entries div #######################################
export const displayEntriesDiv = ( width, perPage, setPerPage, setPaginationStart, setPageNumber) => {
    return (
        <div className={report_classes.select_search_item_div}>
            { width >= 576 && 'Entries'}
            <select className={`${report_classes.form_select}`} 
                id="pagination"  
                name='pagination' 
                type='number' 
                onChange={(e)=>{
                    setPerPage(parseInt(e.target.value))
                    setPageNumber(1)
                    setPaginationStart(true)
                }} value={perPage}>
                    
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
    )
}


// #################################### pagination #######################################
export const handlePagination = (e, pageNumber, totalPages, setPageNumber, setPaginationStart) => {
    const target_page = parseInt(e.target.value)

    

    // console.log("Target page :", target_page, pageNumber, totalPages)

    if(target_page !== pageNumber && target_page>=1 && target_page<=totalPages){
        setPageNumber(target_page)
        setPaginationStart(true)
    }else{
        setPaginationStart(false)
        // console.log("Existing/invalid page :", target_page, pageNumber)
    }
}


// ###################################### global api handler ######################################
export const apiHandler = async (target_url, target_method, target_body, setError) => {
    // console.log("set error :", typeof setError)
    try {
        const API_URL = process.env.REACT_APP_API_URL
        let access_token = localStorage.getItem('access_token')
        let method = target_method.toUpperCase()

        var request_options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+access_token
            }
        }
        if(method === 'POST' || method === 'PUT' || method === 'DELETE'){
            request_options['body'] = JSON.stringify(target_body)
        }
        // console.log(`request option for url ${target_url} is :`, request_options)

        let response = null
        response = await fetch(`${API_URL}/api/v1${target_url}`, request_options)
        let res_code = response.status
        response = await response.json()
        console.log("response data :", target_url, response, res_code)

        if(res_code === 200 && response){
            return response
        }else{
            if(res_code === 401 || res_code === 403){
                toast.error(response.error)
                setError(true)
                // console.log("HERE")
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                return false
            }
        }
    } catch (error) {
        console.log("Api error occurred :", target_url, target_method, target_body, error)
        if (error.response && error.response.status === 401) {
            // console.log("HERE 1")

            localStorage.removeItem('access_token')
            window.location.href = "/login"
        }else{
            return false
        }
    }
}









export const offers_table_headers_limited = ['#', 'Name', 'Status']

// This function retrieves awaiting approvals number for whole app.
export const getAwaitingApprovals = async (dispatch, uiActions) => {
    const API_URL = process.env.REACT_APP_API_URL

    let token = localStorage.getItem('access_token')
    const response = await fetch(`${API_URL}/api/v1/awaiting-offers`, { 
            method: 'GET' ,
            headers: { 
                'Authorization': 'Bearer '+token
        }
    })

    if(response.status === 200) {
        const data = await response.json()
        dispatch(uiActions.setAwaitingOffersNumber(data.awaiting_approvals_len))

    }
}



export const ReportHeaders = (data) => {
    const showInsight = data.showInsight
    let headers_names = Object.entries(data.header_names)  
    
    return (
        <tr className={classes.custom_tr}>
            {headers_names.map((each_item, index)=>{
                if(each_item[0]==='offer' || each_item[0]==='source_medium'){
                    if(showInsight && each_item[0]==='source_medium'){
                        return  <th key={index}>{htmlparser(each_item[1])}</th>
                    }else if(!showInsight && each_item[0]==='offer'){
                        return  <th key={index}>{htmlparser(each_item[1])}</th>
                    }
                }else{
                    return  <th key={index}>{htmlparser(each_item[1])}</th>
                }
            })}
        </tr>
    )
}


export const getStatusButton = (status, item, addTrackerToUrl, handleOfferNameClick) => {
    return(
        <div className={offer_classes.link_button}>
            {status === 'pending' && <div onClick={() => handleOfferNameClick(item)} className={offer_classes.orange_button}>{getStatusText(status)}</div>}
            {status === 'apply' && <div onClick={() => handleOfferNameClick(item)} className={offer_classes.blue_button}>{getStatusText(status)}</div>}
            {/* {status === 'hidden' && <div onClick={() => handleOfferNameClick(item)} className={offer_classes.blue_button}>{getStatusText(status)}</div>} */}
            {(status === 'active' || status === 'hidden') && (
                <div className={offer_classes.green_button} onClick={() => {navigator.clipboard.writeText(addTrackerToUrl(item.url, item.shortner))}}>Copy Link</div>
            )}
        </div>
    )
}



export const getStatusDiv = (status) => {
    return(
        <>
            {status === 'pending' && <div className={classes.orange}>{getStatusText(status)}</div>}
            {status === 'apply' && <div className={classes.blue}>{getStatusText(status)}</div>}
            {status === 'hidden' && <div className={classes.blue}>{getStatusText(status)}</div>}
            {(status === 'active' || status === 'public') && <div className={classes.green}>{getStatusText(status)}</div>}
        </>
    )
}

export const getStatusText = (status) => {
    switch(status){
        case 'active':
            return 'Active'
        case 'public':
            return "Public"
        case 'apply':
            return "Apply to Run"
        case 'pending':
            return "Pending"
        case 'Active':
            return "Active"
        case 'Apply to Run':
            return "Apply to Run"
        case 'Public':
            return "Public"
        case 'Pending':
            return "Pending"
        case 'hidden':
            return "Hidden Offer"
        default:
            return "Select"
    }
}




export const displayDevices = (devices) => {
    let desktops = []
    let mobiles = []
    let tablets = []
    
    Array.from(devices).map(device => {
        if(device === 'mac')
            desktops.push('mac')
        else if(device === 'windows')
            desktops.push('windows')
        else if(device === 'desktopOthers')
            desktops.push('others')
        else if(device === 'mobileAndroid')
            mobiles.push('android')
        else if(device === 'mobileIos')
            mobiles.push('ios')
        else if(device === 'mobileOthers')
            mobiles.push('others')
        else if(device === 'tabletAndroid')
            tablets.push('android')
        else if(device === 'tabletIos')
            tablets.push('ios')
        else if(device === 'tabletOthers')
            tablets.push('others')
    })


    return (
        <div class="devices_parent_div">
        <div class="devices_div">
            <div class="devices">
                <Tooltip title="Desktops" placement="top" arrow><img src={imageDesktop} alt="Desktops"/></Tooltip>:
                {desktops.length > 0 && desktops.map(desktop => {
                    return (
                        <>
                        {desktop === 'mac' && <Tooltip title="Mac" placement="top" arrow><img src={imageApple} alt="Mac"/></Tooltip>}
                        {desktop === 'windows' && <Tooltip title="Windows" placement="top" arrow><img src={imageWindows} alt="Windows"/></Tooltip>}
                        {desktop === 'others' && <Tooltip title="Others" placement="top" arrow><img src={imageOthers} alt="Others"/></Tooltip>}
                        </>
                    )
                })}
            </div>
            <div class="devices">
                <Tooltip title="Smartphones" placement="top" arrow><img src={imagePhone} alt="Smartphones"/></Tooltip>:
                {mobiles.length > 0 && mobiles.map(mobile => {
                    return (
                        <>
                        {mobile === 'ios' && <Tooltip title="Iphone" placement="top" arrow><img src={imageApple} alt='Iphone'/></Tooltip>}
                        {mobile === 'android' && <Tooltip title="Android" placement="top" arrow><img src={imageAndroid} alt='Android'/></Tooltip>}
                        {mobile === 'others' && <Tooltip title="Others" placement="top" arrow><img src={imageOthers} alt='Others'/></Tooltip>}
                        </>
                    )
                })}
            </div>

            <div class="devices">
                <Tooltip title="Tablets" placement="top" arrow><img src={imageTablet} alt='Tablets'/></Tooltip>:
                {tablets.length > 0 && tablets.map(tablet => {
                    return(
                        <>
                        {tablet === 'ios' && <Tooltip title="Ipad" placement="top" arrow><img src={imageApple} alt='Ipad'/></Tooltip>}
                        {tablet === 'android' && <Tooltip title="Android Tablet" placement="top" arrow><img src={imageAndroid} alt='Android Tablet'/></Tooltip>}
                        {tablet === 'others' && <Tooltip title="Others" placement="top" arrow><img src={imageOthers} alt='Others'/></Tooltip>}
                        </>
                    )
                })}
            </div>
        </div>
        </div>
    )
}




const getCountryInfoLabel = (country) => {
    const countryInfo = options.find(option => option.value === country)
    return countryInfo.label
}

export const displayCountries = (countries, left_align) => {
    return (
        <div class='countries_parent_div'>
            <div class={left_align?"countries_left":"countries"}>
                {countries.length > 0 && countries.map(country => {
                    const countryInfo = options.find(option => option.value === country)

                    return (
                        <>
                        {country === 'all' || country === 'ALL' ? (
                            <Tooltip title="Worldwide" placement="top" arrow>
                                <img src={globe} title='Worldwide' alt='Worldwide'/>
                            </Tooltip>
                        ):(
                            <Tooltip title={countryInfo.label} placement="top" arrow>
                                <img src={`https://flagsapi.com/${country}/flat/64.png`} title={()=>getCountryInfoLabel()} alt={()=>getCountryInfoLabel()}/>
                            </Tooltip>
                        )}
                        </>
                    )
                })}
            </div>
        </div>
    )
}




export const displayAllDataOfTargetOffer = (selectedOffer, loading=false, showPopUp, setShowPopUp, applyForOfferHandler, setTermsAgreed, showGoBackBtn=false, setShowEachOfferDiv, setTargetOffer, showOfferName) => {
    return (
        <div className={`${offer_classes.popup_content_main} ${offer_classes.extra_padding}`}>
            <div className={offer_classes.popup_content_left}>
                <div className={offer_classes.popup_item_thumbnail_big}>
                    <div className={offer_classes.popup_headers_thumbnail}>
                        {selectedOffer.thumbnail ? (<img src={selectedOffer.thumbnail} alt="Thumbnail"/>):(<img src={selectedOffer.thumbnail? selectedOffer.thumbnail: foreshopSmallT} alt="thumbnail"/>)}                                
                    </div>
                </div>

                {showOfferName && <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Offer Name</div>
                    <div className={offer_classes.popup_values}>{selectedOffer.offer_name}</div>
                </div>}

                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Payout</div>
                    <div className={offer_classes.popup_values}>
                        {selectedOffer.category === "CPA" && `$ ${selectedOffer.payoutPer}`}
                        {selectedOffer.category === "CPL" && `$ ${selectedOffer.payoutPer}`}
                        {selectedOffer.category === "Content Arbitrage" && `${selectedOffer.payoutPer}%`}
                        {selectedOffer.category === "Search Arbitrage" && `${selectedOffer.payoutPer}%`}
                    </div>
                </div>

                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Price Format</div>
                    <div className={offer_classes.popup_values}>{selectedOffer.category}</div>
                </div>

                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Vertical</div>
                    <div className={offer_classes.popup_values}>{selectedOffer.vertical}</div>
                </div>

                <div className={`${offer_classes.popup_item} ${offer_classes.extra_padding_top}`}>
                    <div className={offer_classes.popup_headers}>Platforms</div>
                    {<div className={offer_classes.popup_values}>
                        <td>
                            {selectedOffer.devices === 'All' ? (
                                <>
                                    Desktop: Mac, Windows, Others
                                    <br />
                                    Mobile:  Android, IOS
                                    <br />                       
                                    Tablet: Android, IOS 
                                </>                              
                            ) : (
                                <>
                                    {displayDevices(selectedOffer.devices)}
                                </>
                            )}
                        </td>
                    </div>
                    }
                </div>
                
                <div className={`${offer_classes.popup_item} ${offer_classes.extra_padding_top}`}>
                    <div className={offer_classes.popup_headers}>Allowed Countries</div>                  
                    {displayCountries(selectedOffer.countries, true)}                    
                </div>
            

                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Status</div>
                    <div className={offer_classes.popup_values}>{getStatusDiv(selectedOffer.offer_status)}</div>
                </div>


                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Affiliate Link</div>
                    <div className={offer_classes.popup_values}>
                        {
                            (selectedOffer.url==="********")?
                            "********":
                            <a href={selectedOffer.url} target='_blank' rel="noreferrer">{selectedOffer.url}</a>
                        }
                    </div>
                </div>


                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Creative Folder</div>
                    <div className={offer_classes.popup_values}>
                        {
                            (selectedOffer.url==="********")?
                            "********":
                            <a href={selectedOffer.creative_url} target='_blank' rel="noreferrer">{selectedOffer.creative_url}</a>
                        }
                    </div>
                </div>



                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Description</div>
                    <div className={offer_classes.popup_values}>{selectedOffer.description}</div>
                </div>

                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Offer Capped</div>  
                    <div className={offer_classes.popup_values}>{selectedOffer.cap}</div>
                </div>

                {/* Restrictions: No spam, No cash back program, no sweeps, no exit traffic */}
                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Restrictions</div> 
                    <div className={offer_classes.popup_values}>{selectedOffer.restrictions}</div>
                </div>

                {/* Network, Contextual, Incentivized, Other, Adware, Pop/Redirect, Social Media, SMS, InApp,
                Messenger, Review maintop_settings_toplabel, TikTok, UAC, SnapChat, Instagram, Youtube, Content Lock, Retargeting */}
                <div className={offer_classes.popup_item}>
                    <div className={offer_classes.popup_headers}>Restricted Media Types</div> 
                    <div className={offer_classes.popup_values}>{selectedOffer.restrictions_media_type}</div>
                </div>
                

                {!showOfferName && (
                    <>
                    {selectedOffer.offer_status==='apply' && (
                        <div className={offer_classes.apply_for_run_div}>
                        <hr className={offer_classes.popup_hr}/>
                        <div className={offer_classes.popup_values_100}>
                            By checking the box below,you agree to promote the offer in accordance with the restrictions above.
                        </div>
                        <div className={offer_classes.popup_agree}> 
                            <input className={offer_classes.popup_checkbox} onChange={(e)=> {setTermsAgreed(e.target.checked)}} id="" type="checkbox"></input>
                            <label className={offer_classes.popup_checkbox_content}>I agree</label> 
                        </div>
                        </div>
                    )}

                    <div className={`${offer_classes.bottom_buttons} ${tools_classes.btn_submit}`}>
                        {selectedOffer.offer_status==='apply' &&
                            <>
                            {loading && showPopUp && <LoadingTable />} 
                            {!showGoBackBtn && <button className={offer_classes.cancel_button} onClick={() => setShowPopUp(false)}>cancel</button>}
                            <button onClick={()=>applyForOfferHandler(selectedOffer.offer)}>Apply for offer</button>
                            </>
                        }
                    </div>
                    </>
                )}



                {showGoBackBtn && 
                <div className={`${offer_classes.bottom_buttons} ${tools_classes.btn_submit}`}>
                    <button className={offer_classes.cancel_button} onClick={() => {
                        setShowEachOfferDiv(false)
                        setTargetOffer()
                    }}>Go Back</button>
                </div>}
            </div>   
        </div>
    )
}




const options = [
    { value: 'AF', label: 'Afghanistan'},
    { value: 'AX', label: 'Aland Islands'},
    { value: 'AL', label: 'Albania'},
    { value: 'DZ', label: 'Algeria'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AD', label: 'Andorra'},
    { value: 'AO', label: 'Angola'},
    { value: 'AI', label: 'Anguilla'},
    { value: 'AQ', label: 'Antarctica'},
    { value: 'AG', label: 'Antigua And Barbuda'},
    { value: 'AR', label: 'Argentina'},
    { value: 'AM', label: 'Armenia'},
    { value: 'AW', label: 'Aruba'},
    { value: 'AU', label: 'Australia'},
    { value: 'AT', label: 'Austria'},
    { value: 'AZ', label: 'Azerbaijan'},
    { value: 'BS', label: 'Bahamas'},
    { value: 'BH', label: 'Bahrain'},
    { value: 'BD', label: 'Bangladesh'},
    { value: 'BB', label: 'Barbados'},
    { value: 'BY', label: 'Belarus'},
    { value: 'BE', label: 'Belgium'},
    { value: 'BZ', label: 'Belize'},
    { value: 'BJ', label: 'Benin'},
    { value: 'BM', label: 'Bermuda'},
    { value: 'BT', label: 'Bhutan'},
    { value: 'BO', label: 'Bolivia'},
    { value: 'BA', label: 'Bosnia And Herzegovina'},
    { value: 'BW', label: 'Botswana'},
    { value: 'BV', label: 'Bouvet Island'},
    { value: 'BR', label: 'Brazil'},
    { value: 'IO', label: 'British Indian Ocean Territory'},
    { value: 'BN', label: 'Brunei Darussalam'},
    { value: 'BG', label: 'Bulgaria'},
    { value: 'BF', label: 'Burkina Faso'},
    { value: 'BI', label: 'Burundi'},
    { value: 'KH', label: 'Cambodia'},
    { value: 'CM', label: 'Cameroon'},
    { value: 'CA', label: 'Canada'},
    { value: 'CV', label: 'Cape Verde'},
    { value: 'KY', label: 'Cayman Islands'},
    { value: 'CF', label: 'Central African Republic'},
    { value: 'TD', label: 'Chad'},
    { value: 'CL', label: 'Chile'},
    { value: 'CN', label: 'China'},
    { value: 'CX', label: 'Christmas Island'},
    { value: 'CC', label: 'Cocos (Keeling) Islands'},
    { value: 'CO', label: 'Colombia'},
    { value: 'KM', label: 'Comoros'},
    { value: 'CG', label: 'Congo'},
    { value: 'CD', label: 'Congo}, Democratic Republic'},
    { value: 'CK', label: 'Cook Islands'},
    { value: 'CR', label: 'Costa Rica'},
    { value: 'CI', label: 'Cote D\'Ivoire'},
    { value: 'HR', label: 'Croatia'},
    { value: 'CU', label: 'Cuba'},
    { value: 'CY', label: 'Cyprus'},
    { value: 'CZ', label: 'Czech Republic'},
    { value: 'DK', label: 'Denmark'},
    { value: 'DJ', label: 'Djibouti'},
    { value: 'DM', label: 'Dominica'},
    { value: 'DO', label: 'Dominican Republic'},
    { value: 'EC', label: 'Ecuador'},
    { value: 'EG', label: 'Egypt'},
    { value: 'SV', label: 'El Salvador'},
    { value: 'GQ', label: 'Equatorial Guinea'},
    { value: 'ER', label: 'Eritrea'},
    { value: 'EE', label: 'Estonia'},
    { value: 'ET', label: 'Ethiopia'},
    { value: 'FK', label: 'Falkland Islands (Malvinas)'},
    { value: 'FO', label: 'Faroe Islands'},
    { value: 'FJ', label: 'Fiji'},
    { value: 'FI', label: 'Finland'},
    { value: 'FR', label: 'France'},
    { value: 'GF', label: 'French Guiana'},
    { value: 'PF', label: 'French Polynesia'},
    { value: 'TF', label: 'French Southern Territories'},
    { value: 'GA', label: 'Gabon'},
    { value: 'GM', label: 'Gambia'},
    { value: 'GE', label: 'Georgia'},
    { value: 'DE', label: 'Germany'},
    { value: 'GH', label: 'Ghana'},
    { value: 'GI', label: 'Gibraltar'},
    { value: 'GR', label: 'Greece'},
    { value: 'GL', label: 'Greenland'},
    { value: 'GD', label: 'Grenada'},
    { value: 'GP', label: 'Guadeloupe'},
    { value: 'GU', label: 'Guam'},
    { value: 'GT', label: 'Guatemala'},
    { value: 'GG', label: 'Guernsey'},
    { value: 'GN', label: 'Guinea'},
    { value: 'GW', label: 'Guinea-Bissau'},
    { value: 'GY', label: 'Guyana'},
    { value: 'HT', label: 'Haiti'},
    { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
    { value: 'VA', label: 'Holy See (Vatican City State)'},
    { value: 'HN', label: 'Honduras'},
    { value: 'HK', label: 'Hong Kong'},
    { value: 'HU', label: 'Hungary'},
    { value: 'IS', label: 'Iceland'},
    { value: 'IN', label: 'India'},
    { value: 'ID', label: 'Indonesia'},
    { value: 'IR', label: 'Iran}, Islamic Republic Of'},
    { value: 'IQ', label: 'Iraq'},
    { value: 'IE', label: 'Ireland'},
    { value: 'IM', label: 'Isle Of Man'},
    { value: 'IL', label: 'Israel'},
    { value: 'IT', label: 'Italy'},
    { value: 'JM', label: 'Jamaica'},
    { value: 'JP', label: 'Japan'},
    { value: 'JE', label: 'Jersey'},
    { value: 'JO', label: 'Jordan'},
    { value: 'KZ', label: 'Kazakhstan'},
    { value: 'KE', label: 'Kenya'},
    { value: 'KI', label: 'Kiribati'},
    { value: 'KR', label: 'Korea'},
    { value: 'KW', label: 'Kuwait'},
    { value: 'KG', label: 'Kyrgyzstan'},
    { value: 'LA', label: 'Lao People\'s Democratic Republic'},
    { value: 'LV', label: 'Latvia'},
    { value: 'LB', label: 'Lebanon'},
    { value: 'LS', label: 'Lesotho'},
    { value: 'LR', label: 'Liberia'},
    { value: 'LY', label: 'Libyan Arab Jamahiriya'},
    { value: 'LI', label: 'Liechtenstein'},
    { value: 'LT', label: 'Lithuania'},
    { value: 'LU', label: 'Luxembourg'},
    { value: 'MO', label: 'Macao'},
    { value: 'MK', label: 'Macedonia'},
    { value: 'MG', label: 'Madagascar'},
    { value: 'MW', label: 'Malawi'},
    { value: 'MY', label: 'Malaysia'},
    { value: 'MV', label: 'Maldives'},
    { value: 'ML', label: 'Mali'},
    { value: 'MT', label: 'Malta'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MQ', label: 'Martinique'},
    { value: 'MR', label: 'Mauritania'},
    { value: 'MU', label: 'Mauritius'},
    { value: 'YT', label: 'Mayotte'},
    { value: 'MX', label: 'Mexico'},
    { value: 'FM', label: 'Micronesia}, Federated States Of'},
    { value: 'MD', label: 'Moldova'},
    { value: 'MC', label: 'Monaco'},
    { value: 'MN', label: 'Mongolia'},
    { value: 'ME', label: 'Montenegro'},
    { value: 'MS', label: 'Montserrat'},
    { value: 'MA', label: 'Morocco'},
    { value: 'MZ', label: 'Mozambique'},
    { value: 'MM', label: 'Myanmar'},
    { value: 'NA', label: 'Namibia'},
    { value: 'NR', label: 'Nauru'},
    { value: 'NP', label: 'Nepal'},
    { value: 'NL', label: 'Netherlands'},
    { value: 'AN', label: 'Netherlands Antilles'},
    { value: 'NC', label: 'New Caledonia'},
    { value: 'NZ', label: 'New Zealand'},
    { value: 'NI', label: 'Nicaragua'},
    { value: 'NE', label: 'Niger'},
    { value: 'NG', label: 'Nigeria'},
    { value: 'NU', label: 'Niue'},
    { value: 'NF', label: 'Norfolk Island'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'NO', label: 'Norway'},
    { value: 'OM', label: 'Oman'},
    { value: 'PK', label: 'Pakistan'},
    { value: 'PW', label: 'Palau'},
    { value: 'PS', label: 'Palestinian Territory}, Occupied'},
    { value: 'PA', label: 'Panama'},
    { value: 'PG', label: 'Papua New Guinea'},
    { value: 'PY', label: 'Paraguay'},
    { value: 'PE', label: 'Peru'},
    { value: 'PH', label: 'Philippines'},
    { value: 'PN', label: 'Pitcairn'},
    { value: 'PL', label: 'Poland'},
    { value: 'PT', label: 'Portugal'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'QA', label: 'Qatar'},
    { value: 'RE', label: 'Reunion'},
    { value: 'RO', label: 'Romania'},
    { value: 'RU', label: 'Russian Federation'},
    { value: 'RW', label: 'Rwanda'},
    { value: 'BL', label: 'Saint Barthelemy'},
    { value: 'SH', label: 'Saint Helena'},
    { value: 'KN', label: 'Saint Kitts And Nevis'},
    { value: 'LC', label: 'Saint Lucia'},
    { value: 'MF', label: 'Saint Martin'},
    { value: 'PM', label: 'Saint Pierre And Miquelon'},
    { value: 'VC', label: 'Saint Vincent And Grenadines'},
    { value: 'WS', label: 'Samoa'},
    { value: 'SM', label: 'San Marino'},
    { value: 'ST', label: 'Sao Tome And Principe'},
    { value: 'SA', label: 'Saudi Arabia'},
    { value: 'SN', label: 'Senegal'},
    { value: 'RS', label: 'Serbia'},
    { value: 'SC', label: 'Seychelles'},
    { value: 'SL', label: 'Sierra Leone'},
    { value: 'SG', label: 'Singapore'},
    { value: 'SK', label: 'Slovakia'},
    { value: 'SI', label: 'Slovenia'},
    { value: 'SB', label: 'Solomon Islands'},
    { value: 'SO', label: 'Somalia'},
    { value: 'ZA', label: 'South Africa'},
    { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
    { value: 'ES', label: 'Spain'},
    { value: 'LK', label: 'Sri Lanka'},
    { value: 'SD', label: 'Sudan'},
    { value: 'SR', label: 'Suriname'},
    { value: 'SJ', label: 'Svalbard And Jan Mayen'},
    { value: 'SZ', label: 'Swaziland'},
    { value: 'SE', label: 'Sweden'},
    { value: 'CH', label: 'Switzerland'},
    { value: 'SY', label: 'Syrian Arab Republic'},
    { value: 'TW', label: 'Taiwan'},
    { value: 'TJ', label: 'Tajikistan'},
    { value: 'TZ', label: 'Tanzania'},
    { value: 'TH', label: 'Thailand'},
    { value: 'TL', label: 'Timor-Leste'},
    { value: 'TG', label: 'Togo'},
    { value: 'TK', label: 'Tokelau'},
    { value: 'TO', label: 'Tonga'},
    { value: 'TT', label: 'Trinidad And Tobago'},
    { value: 'TN', label: 'Tunisia'},
    { value: 'TR', label: 'Turkey'},
    { value: 'TM', label: 'Turkmenistan'},
    { value: 'TC', label: 'Turks And Caicos Islands'},
    { value: 'TV', label: 'Tuvalu'},
    { value: 'UG', label: 'Uganda'},
    { value: 'UA', label: 'Ukraine'},
    { value: 'AE', label: 'United Arab Emirates'},
    { value: 'GB', label: 'United Kingdom'},
    { value: 'US', label: 'United States'},
    { value: 'UM', label: 'United States Outlying Islands'},
    { value: 'UY', label: 'Uruguay'},
    { value: 'UZ', label: 'Uzbekistan'},
    { value: 'VU', label: 'Vanuatu'},
    { value: 'VE', label: 'Venezuela'},
    { value: 'VN', label: 'Viet Nam'},
    { value: 'VG', label: 'Virgin Islands}, British'},
    { value: 'VI', label: 'Virgin Islands}, U.S.'},
    { value: 'WF', label: 'Wallis And Futuna'},
    { value: 'EH', label: 'Western Sahara'},
    { value: 'YE', label: 'Yemen'},
    { value: 'ZM', label: 'Zambia'},
    { value: 'ZW', label: 'Zimbabwe'}
];









/**
 * Accepts a string or a Date Object, and return a Date string with the format of DD/MM/YYYY
 * @param {String | Date} date 
 * @returns String (DD/MM/YYYY)
 */
export const fullYearParser = (date) => {
    if(date === '') return ''

    let temp = new Date(date)
    let month = temp.getMonth()+1
    let day = temp.getDate()

    if(month < 10) month = `0${month}`
    if(day < 10) day = `0${day}`

    return `${day}/${month}/${temp.getFullYear()}`
}

export const parseDateStringToFormat = (date) => {
    if(date === '') return ''

    let temp = new Date(date)
    let month = temp.getMonth()+1
    let day = temp.getDate()

    if(month < 10) month = `0${month}`
    if(day < 10) day = `0${day}`

    return `${temp.getFullYear()}-${month}-${day}`
}



/**
 * Accept a number from 0-11 and return the month name in English
 * @param {number} number 
 * @returns String
 */
export const monthsParser = (number) => {
    let arr = ['January','February','March','April','May','June','July','August','September','October','November','December']

    // if (language === 'HEB') arr = ['ינואר','פרבואר','מרץ','אפריל','מאי','יוני','יולי','אוגוסט','ספטמבר','אוקטובר','נובמבר','דצמבר']

    if(number > arr.length) return arr[number % 11]

    else return arr[number]
}



/**
 * Accept a number from 1-12 and language code ('HEB' or 'ENG') and return the month name
 * according to month input.
 * @param {number} month 
 * @param {string} language 
 * @returns string
 */
export const monthsTranslator = (month,language) => {
    if(language === 'HEB') {
        switch(month) {
            case 1 : return 'ינואר'
            case 2 : return 'פרבואר'
            case 3: return 'מרץ'
            case 4: return 'אפריל'
            case 5: return 'מאי'
            case 6: return 'יוני'
            case 7: return 'יולי'
            case 8 : return 'אוגוסט'
            case 9: return 'ספטמבר'
            case 10: return 'אוקטובר'
            case 11: return 'נובמבר'
            case 12: return 'דצמבר'    
        }
    }
    if(language === 'ENG') {
        switch(month) {
            case 1 : return 'January'
            case 2 : return 'February'
            case 3: return 'March'
            case 4: return 'April'
            case 5: return 'May'
            case 6: return 'June'
            case 7: return 'July'
            case 8 : return 'August'
            case 9: return 'September'
            case 10: return 'October'
            case 11: return 'November'
            case 12: return 'December'    
        }
    }
    return month
}



/**
 * Accept a Date instance, and return a string with the hour and minutes (HH:MM)
 * @param {Date} date 
 * @returns string
 */
export const hoursMinutesParser = (date) => {

    let hours;
    let minutes;

    if(!(date instanceof Date)) return '00:00'

    else {
        if((date.getHours()+1) < 10) hours = `0${date.getHours()+1}`
        else hours = `${date.getHours()+1}`

        if(date.getMinutes() < 10) minutes = `0${date.getMinutes()}`
        else minutes = `${date.getMinutes()}`
    }

    return `${hours}:${minutes}`
}



/**
 * Accept a Status (string) and return it in a prettier format, example 'customer_active' -> 'Customer Active'
 * @param {String} status 
 * @returns string
 */
export const statusParser = (status) => {
    status = status.toLowerCase()

    if(status === 'customer_active') return 'Customer Active'
    if(status === 'company_active') return 'Company Active'
    if(status === 'company_inactive') return 'Company Inactive'
    if(status === 'on_pause') return 'On Pause'
    if(status === 'inactive') return 'Inactive'
    if(status === 'payment_issue') return 'Payment Issue'
    if(status === 'canceling') return 'Canceling'
    if(status === 'black_list') return 'Black List'
    if(status === 'customer_left') return 'Customer Left'

    return status
}



/**
 * Translate the statuses after using statusParser function.
 *
 * @param {String} status 
 * @param {String} language 
 * @returns String
 */
export const statusTranslator = (status,language) => {

    if(language === 'HEB') {
        if(status === 'all') return 'הכל'
        if(status === 'Customer Active') return 'ניהול שוטף'
        if(status === 'Company Active') return 'ניהול שוטף חברה'
        if(status === 'Company Inactive') return 'לא פעיל חברה'
        if(status === 'On Pause') return 'בהקפאה'
        if(status === 'Inactive') return 'לא פעיל'
        if(status === 'Payment Issue') return 'בעית תשלום'
        if(status === 'Canceling') return 'בתהליך ביטול'
        if(status === 'Black List') return 'רשימה שחורה'
        if(status === 'Customer Left') return 'לקוח עזב'
    }

    return status
}



/**
 * 
 * @param {*} confirmations Object
 * @param {*} declines Object
 * @returns Object
 */
export const OnAirStatusObjectsCombine = (confirmations, declines) => {

    return {
        ads: declines.ads ? !declines.ads : confirmations.ads,
        articles: declines.articles ? !declines.articles : confirmations.articles,
        campaigns: declines.campaigns ? !declines.campaigns : confirmations.campaigns,
        product: declines.product ? !declines.product : confirmations.product,
        seo: declines.seo ? !declines.seo : confirmations.seo,
        social: declines.social ? !declines.social : confirmations.social
    }

    
}



/**
 * Accept a url as a string and return it as a 'cleaned' format
 * for example: https://www.example.com/ -> example.com
 * @param {String} str 
 * @returns String
 */
export const urlCleaner = (str) => {
    const regex = new RegExp('(http|https)://')
    let clean_url = str.replace(regex, '').replace('www.','').replace('/', '')

    return clean_url
}



/**
 * Accept a string|number|boolean and return its boolean equivalent.
 * for example: 'true' -> true , 1 -> true
 * @param {string | boolean | number} value 
 * @returns 
 */
export const booleanParser = (value) => {
    return (value === 'true' || value === 1 || value === '1' || value === true)
}



/**
 * Recieve a string and push it to the user copyclipboard, in other words
 * when executed user will be able to paste the string given as argument.
 * @param {String} text 
 * @returns 
 */
export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text)

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea")
        textarea.textContent = text;
        textarea.style.position = "fixed"  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
            return document.execCommand("copy")  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex)
            return prompt("Copy to clipboard: Ctrl+C, Enter", text)
        }
        finally {
            document.body.removeChild(textarea)
        }
    }
}



/**
 * This function accept the screen width, you can use the useWindowSize hook to get it,
 * and a boolean called navbarminimized which you can get from uiSlice in the global store.
 * return a correct width for the table.
 * @param {number} screenWidth 
 * @param {boolean} navbarminimized 
 * @returns number
 */
export const TableWidthHelper = (screenWidth,navbarminimized) => {
    const width_to_remove = navbarminimized ? 200 : 350

    return screenWidth - width_to_remove
}



/**
 * This function is the same as normal timeout, but enforcing a default execution time.
 * @param {Function} user_function 
 * @param {number} usertime 
 */
export const TimeOutHandler = (user_function,usertime) => {

    let time = usertime ? usertime : 2500

    setTimeout(() => {
        user_function()
    },time)
}



/**
 * payment Statuses are coming by default in english, incase language code is 'HEB' it will translate them into Hebrew
 * @param {String} status 
 * @param {String} language 
 * @returns String
 */
export const paymentsStatusesHandler = (status,language) => {
    switch(status) {
        case 'processing':
            if(language === 'HEB') return 'בתהליך'
            return status;
        case 'request accepted':
            if(language === 'HEB') return 'בקשה התקבלה'
            return status;
        case 'request declined':
            if(language === 'HEB') return 'בקשה לא התקבלה'
            return status;
        case 'payment process':
            if(language === 'HEB') return 'הועבר לתשלום'
            return status
        case 'paid':
            if(language === 'HEB') return 'שולם'
            return status;
        default:
            return status
            
    }
}



/** 
 * -- This Function is only used as a helper function to paymentAdminCSVHandler --
 * Accept a paymentRequest boolean (receipt_status, tax_recept_invoice, client_check), their matching refuse reason, and language code ('HEB','ENG')
 * and return the value needed to be represnted on the CSV according to those parameters.
 * @param {Boolean} bool 
 * @param {string} reason 
 * @param {string} language 
 * @returns boolean
 */
const paymentCSVBooleanParser = (bool,reason,language) => {

    if(language === 'HEB') {
        if(bool) return 'אושר'
        if(reason === '') return 'טרם הוחלט'
        if(!bool) return 'סורב'
    }
    else {
        if(bool) return 'Approved'
        if(reason === '') return 'No Status'
        if(!bool) return 'Declined'
    }

    return bool
}



/**
 * Recieve a array of paymentRequests and the desired language ('HEB' or 'ENG') return a array fitting the CSV maker
 * @param {Array} data 
 * @param {String} language 
 * @returns 
 */
export const CreativesCSVHandler = (data,language) => {

    let new_data;

    if(language === 'HEB') {
        new_data = data.map((obj) => {
            return {
                סטטוס: paymentsStatusesHandler(obj.request_status,language),
                'שם לקוח': `${obj.customer.firstName} ${obj.customer.lastName}`,
                נוצר: fullYearParser(obj.created),
                'סכום בש"ח': obj.amount.NIS,
                'אישור מסמך': paymentCSVBooleanParser(obj.receipt_status,obj.refuse_reason.receipt_status_reason,language),
                'אישור קבלה/חשבונית': paymentCSVBooleanParser(obj.receipt_tax_invoice,obj.refuse_reason.receipt_tax_invoice_reason,language),
                'אישור לקוח': paymentCSVBooleanParser(obj.client_check,obj.refuse_reason.client_check_reason,language),
                'תאריך אישור': obj.approved_date !== 'None' ? fullYearParser(obj.approved_date) : 'לא אושר',
                'חיוב זיכוי': obj.charge_refund ? 'חיובי' : '',
                'מספר חשבונית חיוב זיכוי': obj.charge_refund ? obj.charge_refund_details.invoice_id : ''
            }
        })
    }
    else {
        new_data = data.map((obj) => {
            return {
                Status: paymentsStatusesHandler(obj.request_status,language),
                'Client Name': `${obj.customer.firstName} ${obj.customer.lastName}`,
                'Created': fullYearParser(obj.created),
                'Amount NIS': obj.amount.NIS,
                'Receipt Status': paymentCSVBooleanParser(obj.receipt_status,language),
                'Tax Invoice Status': paymentCSVBooleanParser(obj.receipt_tax_invoice,language),
                'Client Check': paymentCSVBooleanParser(obj.client_check,language),
                'Approvment Data': obj.approved_date !== 'None' ? fullYearParser(obj.approved_date) : 'Not Approved',
                'Charge Refund': obj.charge_refund ? 'Positive' : '',
                'Charge Refund Invoice ID': obj.charge_refund ? obj.charge_refund_details.invoice_id : ''
            }
        })
    }

    return new_data

}



// export const websiteControlCSVHandler = (data,language) => {

//     let new_data = []
//     let name = '---' 

//     if(language === 'HEB') {
//         new_data = data.map((item) => {
//         const added = fullYearParser(item.added*1000)
//         console.log(item)

//         if(item.customers[0]) {
//             name = `${item.customers[0]?.firstName} ${item.customers[0]?.lastName}`
//         }   

//             return {
//                 'הכנסות בשקלים': item.earned.NIS,
//                 'הכנסות בדולרים': item.earned.USD,
//                 'נכס': item.type === 'REGULAR' ? 'אתר' : 'פורטל',
//                 'נוסף': added,
//                 'סטטוס': statusTranslator(statusParser(status), 'HEB'),
//                 'רייטניר': item.retainer,
//                 'דומיין': item.domain,
//                 'לקוח': name
//             }
//         })
//     }

//     else {
//         new_data = data.map((item) => {
//             const added = fullYearParser(item.added*1000)
//             console.log(item.customers)
//             if(item.customers[0]) {
//                 name = `${item .customers[0]?.firstName} ${item.customers[0]?.lastName}`
//             } 

//             return {
//                 'Income NIS': item.earned.NIS,
//                 'Income USD': item.earned.USD,
//                 'Type': item.type,
//                 'Added': added,
//                 'Status': statusParser(status),
//                 'Retainer': item.retainer,
//                 'Domain': item.domain,
//                 'Customer': name
//             }
//         })
//     }

//     return new_data
// }



/**
 * Generate Crisp chat
 */
export const generateChat = (role) => {

    if(role === 'CUSTOMER' || true) {
        const script = document.createElement("script")
        script.setAttribute("async","async")
        script.src = "https://client.crisp.chat/l.js"
        
        document.head.appendChild(script)
    }
    
}



/**
 * push data to crisp
 * @param {Object} data 
 */
export const crispPushUserData = (data) => {

    let phone = data.phone ? data.phone : ''

    if(phone.startsWith('05')) phone.replace('05','+972')
    if(phone.startsWith('5')) phone.replace('5','+972')
    if(phone.startsWith('972')) phone.replace('972','+972')

    const details = {
        nickname: `${data.lastName} ${data.firstName}`,
        email: data.email,
        phone: phone,
        company: data.companyName
    }

    for (let key of Object.keys(details)) {
        window.$crisp.push(["set", `user:${key}`, [details[ key ]]])
    }

}



/**
 * Clear All timeouts, used to clear unrequired http requests on various search inputs
 */
export const clearAllTimeouts = () => {
    var highestTimeoutId = setTimeout("")
    for (var i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i) 
    }
}
